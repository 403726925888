/* General container layout */
.contact-container {
    display: flex;
    justify-content: space-between;
    align-items: stretch; /* This will make all columns the same height */
    padding: 40px;
    background-color: #e9f0f7;
    border-radius: 10px;
    max-width: 1200px;
    margin: 40px auto;
    min-height: calc(100vh - 200px);
}

/* Left section */
.contact-left {
    width: 25%;
    padding-right: 20px;
}

.contact-left h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #333;
}

.contact-left p {
    font-size: 1.2rem;
    color: #333;
    margin-bottom: 15px;
}

/* Right section form */
.contact-right {
    width: 45%;
    padding: 0 20px;
}

.contact-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.form-group label {
    margin-bottom: 5px;
    font-weight: bold;
    color: #333; /* Dark gray color for labels */
}

.name-fields {
    display: flex;
    gap: 15px;
}

.name-fields .form-group {
    flex: 1;
}

input,
textarea {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    background-color: #fff;
    color: #333;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
}

textarea {
    height: 120px;
    resize: vertical;
}

.submit-btn {
    margin-top: 15px;
    padding: 12px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 1.2rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.submit-btn:hover {
    background-color: #0056b3;
}

/* Chatbot area */
.chatbot-area {
    width: 30%;
    padding-left: 20px;
    border-left: 1px solid #ccc;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.chatbot-coming-soon {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.chatbot-icon {
    font-size: 3rem;
    margin-bottom: 10px;
    color: #007bff;
}

/* Error and success message styling */
.error-message {
    color: #dc3545;
    margin-top: 15px;
}

.success-message {
    color: #28a745;
    margin-top: 15px;
}

/* Responsive behavior */
@media (max-width: 768px) {
    .contact-container {
        flex-direction: column;
        padding: 20px;
    }

    .contact-left,
    .contact-right,
    .chatbot-area {
        width: 100%;
        padding: 20px 0;
        border-left: none;
    }

    .name-fields {
        flex-direction: column;
    }
}

/* Add these new styles for better visibility of placeholder text */
input::placeholder,
textarea::placeholder {
    color: #999; /* Light gray color for placeholder text */
}

/* Style for focused input fields */
input:focus,
textarea:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 0 2px rgba(0,123,255,0.25);
}

/* Adjust text color for better readability */
.contact-left p {
    color: #333;
}

/* Enhance input field appearance */
input,
textarea {
    border: 1px solid #ccc;
    background-color: #fff;
    color: #333;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
}

/* Add more spacing between form elements */
.form-group {
    margin-bottom: 20px;
}

/* Style the submit button with hover effect */
.submit-btn {
    transition: background-color 0.3s ease;
}

.submit-btn:hover {
    background-color: #0056b3;
}

/* Ensure the chatbot area has a white background for contrast */
.chatbot-area {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

/* Base Styles for Contact Page */
.contact {
    padding: 2rem;
    text-align: center;
}

.cta-btn {
    padding: 12px 30px;
    margin: 10px;
    color: white;
    text-decoration: none;
    font-size: 16px;
    background-color: #007bff;
    border-radius: 5px;
    transition: all 0.3s;
    font-weight: bold;
}

.cta-btn.primary:hover {
    background-color: #0056b3;
}
