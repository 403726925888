/* Base Styles */
body, html {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    background-color: #ffffff;
}

/* Home Layout */
.home {
    position: relative;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    overflow: hidden;
}

/* Video Background */
.background-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
    display: block !important;
    visibility: visible !important;
    opacity: 1 !important;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 0;
}

/* Content Container */
.content-container {
    position: relative;
    text-align: center;
    max-width: 80%;
    padding: 2rem;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    z-index: 1;
    transition: all 0.3s ease-in-out;
    animation: fadeIn 1s ease-out;
}

.content-container:hover {
    transform: scale(1.02);
}

.content-container h1 {
    font-size: 3rem;
    margin-bottom: 1rem;
    color: #ffffff;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8);
}

.content-container p {
    font-size: 1.2rem;
    margin-bottom: 2rem;
    color: #f0f0f0;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8);
    -webkit-text-stroke: 1px rgba(0, 0, 0, 0.3);
}

/* CTA Buttons */
.cta-buttons {
    margin-top: 20px;
}

.cta-btn {
    padding: 12px 30px;
    margin: 10px;
    color: white;
    text-decoration: none;
    font-size: 16px;
    border-radius: 5px;
    transition: all 0.3s;
    font-weight: bold;
}

.cta-btn.primary {
    background-color: #007bff;
}

.cta-btn.secondary {
    background-color: rgba(255, 255, 255, 0.1);
    border: 2px solid white;
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.3);
}

.cta-btn:hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 8px rgba(0, 255, 255, 0.3);
}

.cta-btn:active {
    transform: translateY(-1px);
    box-shadow: 0 2px 4px rgba(0, 255, 255, 0.3);
}

.cta-btn.primary:hover {
    background-color: #0056b3;
}

.cta-btn.secondary:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

/* Chat Components */
.chat-icon {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 60px;
    height: 60px;
    background: linear-gradient(135deg, #0a2a3b 0%, #1e4d6b 100%);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    transition: all 0.3s ease;
    z-index: 1000;
}

.chat-icon:hover {
    transform: scale(1.1);
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
}

.chat-wrapper {
    position: fixed;
    bottom: 20px;
    right: 0;
    z-index: 1000;
    pointer-events: none;
}

/* Update chat container styles */
.chat-container {
    width: 600px;
    height: 85vh;
    background-color: white;
    border-radius: 8px 0 0 8px;
    box-shadow: 0 12px 28px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    pointer-events: auto;
    overflow: hidden;
    transform: translateZ(0);
    -webkit-transform: translateZ(0);
    backface-visibility: hidden;
    will-change: transform;
    margin: 0;
    padding: 0;
}

/* Update iframe container styles */
.chat-iframe-container {
    flex: 1;
    display: flex;
    min-height: 0;
    height: calc(100% - 60px);
    width: 100%;
    margin: 0;
    padding: 0;
}

/* Update iframe styles */
.chat-iframe-container iframe {
    width: 100%;
    height: 100%;
    border: none;
    margin: 0;
    padding: 0;
    display: block;
}

/* Update chat header */
.chat-header {
    padding: 10px 15px;
    background: linear-gradient(135deg, #0a2a3b 0%, #1e4d6b 100%);
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    flex-shrink: 0;
    margin: 0;
    padding: 0 15px;
}

.chat-header h3 {
    margin: 0;
    font-size: 1.1rem;
    font-weight: 500;
}

.chat-header-controls {
    display: flex;
    align-items: center;
    gap: 8px;
}

.minimize-button,
.close-button {
    background: none;
    border: none;
    cursor: pointer;
    color: white;
    transition: all 0.2s ease;
    padding: 4px 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.minimize-button:hover,
.close-button:hover {
    opacity: 0.8;
    transform: scale(1.1);
}

.minimize-button {
    font-size: 20px;
}

.close-button {
    font-size: 24px;
}

/* Chat Window */
.chat-iframe-container {
    flex: 1;
    display: flex;
    min-height: 0;
    height: calc(100% - 60px);
    width: 100%;
}

.chat-iframe {
    width: 100%;
    height: 100%;
    border: none;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
}

/* Animation Keyframes */
@keyframes fadeIn {
    from { opacity: 0; transform: translateY(20px); }
    to { opacity: 1; transform: translateY(0); }
}

/* Media Queries */
@media (max-width: 768px) {
    .hero h1, h1 {
        font-size: 2.2rem;
    }
    .hero p, p {
        font-size: 1rem;
    }
    .cta-buttons {
        flex-direction: column;
    }
    .content-container {
        padding: 1rem;
        max-width: 95%;
    }
    .cta-btn {
        padding: 10px 20px;
        font-size: 14px;
    }
    .chat-container {
        width: 100%;
        height: 100vh;
        bottom: 0;
        right: 0;
        left: 0;
        margin: 0;
        border-radius: 0;
        max-width: 100%;
    }
    .chat-icon {
        bottom: 15px;
        right: 15px;
        width: 50px;
        height: 50px;
    }
}

@media (min-width: 769px) and (max-width: 1024px) {
    .chat-container {
        width: 500px;
        height: 85vh;
        right: 0;
        margin-right: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
}

@media (max-width: 480px) {
    h1 {
        font-size: 1.8rem;
    }
    .chat-container {
        width: 100%;
        height: 100vh;
        right: 0;
        left: 0;
        bottom: 0;
        margin: 0;
        border-radius: 0;
    }
}

@media (max-height: 1100px) {
    .chat-container {
        height: calc(100vh - 100px);
    }
}
