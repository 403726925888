/* General Reset */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* Page Layout */
body {
    font-family: Arial, sans-serif;
    color: #333;
    background-color: #f9f9f9;
    line-height: 1.6;
}

/* Container for the solutions section */
.container {
    width: 90%;  /* Set container to 90% of the page width */
    margin: 0 auto;  /* Center the container */
    padding: 20px;
    box-sizing: border-box;
}

/* Flexbox layout for solution items */
.solution-items {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    gap: 2rem;
}

/* Solution item styles */
.solution-item {
    display: flex;
    flex-direction: row;
    align-items: stretch;  /* Ensures the text and image are aligned */
    width: 100%;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    background-color: #f8f9fa;
    margin-bottom: 2rem;
    box-sizing: border-box;
}

.solution-content {
    flex: 1;  /* Ensures text takes up available space */
    padding-right: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;  /* Aligns the text vertically */
}

.solution-item img {
    flex: 0 0 30%; /* Image takes up 30% of the width */
    max-width: 30%;
    height: auto;
    border-radius: 8px;
    object-fit: cover;
    align-self: center;  /* Ensures image is centered within its container */
}

/* For alternating layout on even rows */
.solution-item:nth-child(even) {
    flex-direction: row-reverse; /* Image on the left, text on the right for even items */
}

.solution-item:nth-child(even) .solution-content {
    padding-left: 2rem;
    padding-right: 0;
}

/* Ensure consistent title and subheading styles */
/* Center align the "Our Solutions" title and adjust its size */
.solutions-title {
    font-size: 2.5em;  /* Keep the font size as it is */
    text-align: center;  /* Keep the title centered */
    color: #000;  /* Set the text color to black */
    background-color: #fff;  /* Set the background color to white */
    padding: 20px;  /* Add some padding to the title for spacing */
    margin-bottom: 20px;  /* Add some spacing below the title */
    border-radius: 8px;  /* Optional: add rounded corners to the background */
}

.solutions-intro {
    background-color: #f1f1f1; /* Light gray background */
    color: #333; /* Keep dark text */
    padding: 20px;
    border-radius: 8px;
}

.solutions-intro {
    font-size: 1.25em;
    /* Increase the font size */
    color: #333;
    /* Maintain a dark text color for readability */
    line-height: 1.8;
    /* Improve readability by increasing line spacing */
    margin-bottom: 20px;
    /* Add some spacing below */
    text-align: center;
    /* Optionally center-align the text */
}

.solution-content h3 {
    font-size: 1.8em;  /* Consistent with subheadings in Our Services */
    margin-bottom: 1rem;
    color: #333;
}

/* Ensure consistent paragraph and list styles */
.solution-content p,
.solution-content li {
    font-size: 1.2em;  /* Match the font size of Our Services */
    line-height: 1.6;
    color: #555;
}

.solution-content ul {
    list-style-type: disc;
    padding-left: 1.5rem;
    margin-bottom: 1rem;
}

.solution-content li {
    margin-bottom: 0.5rem;
}

/* Responsive behavior */
@media (max-width: 768px) {
    .solution-item {
        flex-direction: column; /* Stack text and image vertically */
    }

    .solution-content,
    .solution-item img {
        width: 100%; /* Text and image take full width */
        padding: 0;
    }

    .solution-item img {
        margin-bottom: 1rem; /* Add spacing between image and text */
    }
}

.contact-us-section {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding: 2rem;
}

.contact-form-container {
    background-color: #f5f5f5;
    border-radius: 10px;
    padding: 2rem;
    max-width: 500px;
    width: 100%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.contact-form-container h2 {
    text-align: center;
    margin-bottom: 1.5rem;
    color: #333;
}

.contact-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.contact-form input,
.contact-form textarea {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.contact-form button {
    background-color: #0066cc;
    color: white;
    border: none;
    padding: 0.75rem;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.contact-form button:hover {
    background-color: #0052a3;
}
