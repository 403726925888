/* About Us Section */
/* About Us Section */
.about-us-section {
    position: relative;
    padding: 50px 20px 30px; /* Reduced bottom padding */
    text-align: center;
    background-color: white; /* Remove any background-image or overlay here */
    color: #000000; /* Use black text for contrast on white background */
}

/* Increase font size and change color for headings */
.about-us-section h1 {
    font-size: 3.5rem; /* Increased font size */
    color: black;
    margin-bottom: 30px;
    text-align: center;
    width: 100%;
}

.about-us-section h2 {
    font-size: 2.5rem;
    color: black;
    margin-bottom: 15px;
}

/* Change text color to black */
.about-us-section p {
    color: black;
    font-size: 1rem;
    line-height: 1.6;
}

/* Remove any existing overlay */
.about-us-section::before {
    content: none;
}

/* Adjust spacing between sections */
.about-us-section {
    padding-bottom: 20px; /* Reduce bottom padding */
}

/* Ensure all text is visible */
.about-us-section h1,
.about-us-section h2,
.about-us-section p,
.expertise-section h2,
.expertise-item h3,
.expertise-item p {
    color: black;
    text-shadow: none; /* Remove any text shadow */
}

.about-us-content {
    display: flex;
    flex-wrap: wrap; /* Allows wrapping of content if screen is small */
    justify-content: space-between; /* Space between text and image */
    align-items: flex-start; /* Vertically center text and image */
    gap: 20px; /* Adds spacing between items */
    padding: 0 20px 20px; /* Reduced padding */
}

.about-us-text {
    flex: 1;
    text-align: left;
    max-width: 50%; /* Ensure text takes half the width */
}

.about-us-text h2 {
    text-align: left;
}

.about-us-image {
    flex: 1;
    max-width: 40%; /* Limit image width for balance */
    flex-shrink: 0; /* Prevent image from shrinking */
}

.about-us-text p {
    margin-bottom: 1.5rem;
}

.who-we-are-image {
    width: 100%;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.title-large, .title-secondary {
    color: #ffffff; /* White text */
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5); /* Subtle shadow */
}

@media (max-width: 768px) {
    .about-us-content {
        flex-direction: column;
        align-items: center;
    }

    .about-us-text, .about-us-image {
        max-width: 100%;
        text-align: center; /* Center text on smaller screens */
    }
}

/* Expertise Section */
.expertise-section {
    padding: 50px 0;
    padding-top: 30px; /* Reduced top padding */
}

/* Ensure "Our Expertise" heading is also black and larger */
.expertise-section h2 {
    font-size: 2.5rem;
    color: black;
    text-align: left;
    margin-bottom: 30px;
}

.expertise-areas {
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.expertise-item {
    flex: 1;
    background-color: #f5f5f5;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.expertise-item img {
    width: 100%;
    height: 200px; /* Fixed height for all images */
    object-fit: cover;
}

.expertise-item h3 {
    padding: 15px;
    margin: 0;
    font-size: 1.2rem;
}

.expertise-item p {
    padding: 0 15px 15px;
    margin: 0;
    font-size: 0.9rem;
}

/* Apply background image only to Digital Transformation card */
.expertise-item.digital-transformation {
    padding: 20px;
    border-radius: 8px;
    color: #fff; /* Ensure text is visible on the image */
    background-size: cover;
    background-position: center;
}

.expertise-item.digital-transformation h3, 
.expertise-item.digital-transformation p {
    color: #fff; /* Ensure the text is white for visibility */
}

/* Adjust card backgrounds for better contrast */
.expertise-item {
    background-color: #f5f5f5;
}

.expertise-item h3,
.expertise-item p {
    color: black;
}

/* New Team Section */
.about-header {
    text-align: center;
    padding: 2rem 0;
    background-color: #f8f9fa;
}

.about-header h1 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
}

.about-header p {
    font-size: 1.2rem;
    max-width: 800px;
    margin: 0 auto;
}

.team-members {
    background-color: rgba(70, 165, 168, 0.3);
    padding: 3rem 0;
}

.team-member {
    display: flex;
    align-items: flex-start;
    max-width: 1200px;
    margin: 0 auto 3rem;
    padding: 2rem;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.team-member img {
    width: 200px;
    height: 200px;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 2rem;
}

.member-info {
    flex: 1;
}

.member-info h2 {
    font-size: 1.8rem;
    margin-bottom: 1rem;
}

.member-info p {
    font-size: 1rem;
    line-height: 1.6;
}

@media (max-width: 768px) {
    .team-member {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .team-member img {
        margin-right: 0;
        margin-bottom: 1rem;
    }
}
