/* App.css */
body, html {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    background-color: #000;
    color: #fff;
    height: 100%; /* Allow the height to expand */
    overflow: auto; /* Ensure scrollable content */
}

html {
    scroll-behavior: smooth;
}

.App {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    overflow: hidden; /* Add this line */
}

.main-content {
    flex: 1;
    overflow-y: auto;
    padding-top: 60px; /* Add padding to push content below the header */
    box-sizing: border-box; /* Ensure padding is included in the element's total height */
    height: calc(100vh - 60px); /* Add this line */
}


/* Header Styles */
.header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.7);
    padding: 1rem 2rem;
    z-index: 1000;
    height: 60px; /* Set a fixed height for the header */
}

.header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
}

.logo {
    height: 40px;
}

.nav-links {
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.nav-links li {
    margin-left: 1.5rem;
}

.nav-links a {
    color: #fff;
    text-decoration: none;
    font-size: 1rem;
    transition: color 0.3s ease;
}

.nav-links a:hover {
    color: #00a8ff;
}

/* Button Styles */
.cta-btn {
    padding: 0.75rem 1.5rem;
    border-radius: 5px;
    font-size: 1rem;
    text-decoration: none;
    transition: all 0.3s ease;
    display: inline-block;
}

.cta-btn.primary {
    background-color: #00a8ff;
    color: #fff;
    border: none;
}

.cta-btn.primary:hover {
    background-color: #0086cc;
}

.cta-btn.secondary {
    background-color: transparent;
    color: #00a8ff;
    border: 2px solid #00a8ff;
}

.cta-btn.secondary:hover {
    background-color: rgba(0, 168, 255, 0.1);
}

/* Responsive Design */
@media (max-width: 768px) {
    .header-content {
        flex-direction: column;
        align-items: flex-start;
    }

    .nav-links {
        margin-top: 1rem;
        flex-direction: column;
    }

    .nav-links li {
        margin-left: 0;
        margin-bottom: 0.5rem;
    }
}

.background-video {
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -1;
    object-fit: cover;
}

/* Add these new styles */
.home-page {
    height: auto;
    min-height: calc(100vh - 60px);
}

.page-component {
    padding-top: 20px;
    min-height: calc(100vh - 80px); /* 60px header + 20px padding */
}
