.expertise-section {
    text-align: center;
    padding: 50px 20px;
}

.expertise-grid {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
}

.expertise-card {
    background-color: #fff;
    border-radius: 8px;
    padding: 20px;
    max-width: 300px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.expertise-image {
    width: 100%;  /* Ensure the image spans the entire width of the container */
    height: 200px;  /* Set a fixed height for uniformity */
    object-fit: cover;  /* Ensure the image covers the area without distortion */
    border-radius: 8px;
    margin-bottom: 20px;
}


.expertise-card h3 {
    font-size: 1.25rem;
    margin-bottom: 15px;
}

.expertise-card p {
    font-size: 1rem;
    color: #555;
    margin-bottom: 20px;
}

.learn-more {
    color: #007BFF;
    text-decoration: none;
    font-weight: bold;
}

.learn-more:hover {
    text-decoration: underline;
}
