.demo-container {
    padding: 30px;
    background-color: #f5f5f5;
    color: #333;
    max-width: 1200px;
    margin: 0 auto;
}

.demo-section {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 6px rgba(0,0,0,0.1);
    padding: 20px;
    margin-bottom: 30px;
}

.demo-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 30px;
}

.demo-text {
    flex: 1;
    max-width: 50%;
}

.demo-image {
    flex: 1;
    max-width: 50%;
}

.demo-image img {
    width: 100%;
    height: 250px; /* Fixed height for consistency */
    object-fit: cover;
    border-radius: 8px;
}

h1 {
    color: #333;
    text-align: center;
    margin-bottom: 30px;
}

h2 {
    color: #333;
    margin-bottom: 15px;
    font-size: 1.5em;
}

p {
    line-height: 1.6;
    font-size: 1em;
    color: #444; /* Darker text for better contrast */
}

.watch-demo-btn {
    display: inline-block;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    font-weight: bold;
    transition: background-color 0.3s, transform 0.2s;
}

.watch-demo-btn:hover {
    background-color: #0056b3;
    transform: translateY(-2px);
}

/* Add these new styles at the end of your existing CSS */

.watch-demo-btn {
    position: relative;
    /* ... existing styles ... */
}

.watch-demo-btn::after {
    content: "Coming Soon";
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    background-color: #333;
    color: white;
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 0.8em;
    opacity: 0;
    transition: opacity 0.3s, transform 0.3s;
    pointer-events: none;
    white-space: nowrap;
}

.watch-demo-btn:hover::after {
    opacity: 1;
    transform: translateX(-50%) translateY(-5px);
}

/* Alternate layout for even sections */
.demo-section:nth-child(even) .demo-content {
    flex-direction: row-reverse;
}

/* Responsive design */
@media (max-width: 768px) {
    .demo-content {
        flex-direction: column !important;
    }

    .demo-text, .demo-image {
        max-width: 100%;
    }

    .demo-image img {
        height: auto;
        max-height: 250px;
    }
}
