/* Header container */
.header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: rgba(51, 51, 51, 0.8); /* Semi-transparent background */
    padding: 0 20px;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: space-between; /* Spread logo and nav */
    height: 120px; /* Increased height */
}

/* Logo container */
.logo-container {
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    border-radius: 10px;
}

.logo {
    width: auto; /* Let width adjust automatically */
    height: 90px; /* Increased height */
    max-width: 200px; /* Limit maximum width */
    filter: drop-shadow(0 0 8px rgba(0, 255, 255, 0.7)); /* Increased glow effect */
}

/* Navigation menu */
.nav {
    display: flex;
    justify-content: flex-end; /* Align to the right */
    flex-grow: 1; /* Take up remaining space */
}

.nav-links {
    list-style-type: none;
    display: flex;
    gap: 30px; /* Increased gap */
    margin: 0;
    padding: 0;
}

.nav-links li {
    margin: 0;
}

.nav-links a {
    color: white;
    text-decoration: none;
    font-size: 18px; /* Slightly larger font */
    font-weight: bold;
    transition: color 0.3s ease, text-shadow 0.3s ease, transform 0.3s ease;
    cursor: pointer;
}

.nav-links a:hover,
.nav-links a:focus {
    color: #00ffff;
    text-shadow: 0 0 10px rgba(0, 255, 255, 0.7);
    transform: translateY(-2px);
}

.nav-links a:active {
    color: #00cccc;
}

/* Remove this rule to allow color changes */
/* .nav-links a:visited {
    color: white;
} */

@media (max-width: 768px) {
    .header {
        height: 100px; /* Slightly reduced height for mobile */
        padding: 0 10px; /* Reduced padding */
    }

    .logo {
        height: 70px; /* Smaller logo for mobile */
        max-width: 150px;
    }

    .nav-links {
        gap: 15px; /* Reduced gap for mobile */
    }

    .nav-links a {
        font-size: 16px; /* Smaller font for mobile */
    }
}
